import { useState } from 'react'
import './app.css'
import Firebase, { removeAuth, getUserDisplayName } from './services/firebase/Firebase'
import { AppContext } from './contexts/AppContext'
import FlightPanel from './components/FlightPanel'
import configValues from './AppInit';

function App() {
  /* the login state starts as null,
   * giving Firebase time to ascertain the auth state.
   * Friebase will update the login state
   */
  const [isLoggedIn, setLoggedIn] = useState(null)
  console.log("Using:" + configValues.apiUrl + " " + process.env.NODE_ENV)

  let content;

  if (isLoggedIn === null || !isLoggedIn) {
    content = (
      <>
        <Firebase />
      </>
    )
  } else {

    content = (
      <div className="content_default">
        {/* <FlightPanel /> */}
        <div className="message"><b>Hello{(getUserDisplayName())?", "+getUserDisplayName():""}!</b></div>
        <div className="message">Seeing as we're still waiting for flight details, maybe have a quick look at the hotel we're staying in near Amsterdam:<br/>
        <a href="https://www.nh-hotels.com/en/hotel/nh-amsterdam-schiphol-airport?campid=8435708&gad_source=1&gclid=CjwKCAjw_ZC2BhAQEiwAXSgClpdGRx9S1CEfTxcAuxisFG6yr5kh2eXqCa_5fqSv2asW-Ni7a4YnrBoC5CMQAvD_BwE&gclsrc=aw.ds" target="_blank" rel="noreferrer">NH Amsterdam Schipol Airport</a>
        </div>
        <button className="submit_button rounded_corners" onClick={() => {
          window.location.reload();
        }}>Refresh</button>
      </div>
    )
  }

  return (
    <AppContext.Provider value={{isLoggedIn, setLoggedIn}}>
      <div className="App">
        {content}
      </div>
    </AppContext.Provider>
  );
}

export default App;